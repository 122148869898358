<template>
  <roughness-template
    :skin-roughness="skinRoughness"
    :exfoliate-skin="exfoliateSkin"
    :chemical-peeling="chemicalPeeling"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import RoughnessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/roughness/RoughnessTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const { requiredField, field } = fieldBuilder;

const fieldsToReset = [
  'textureDiscomfort',
  'firstPatchFlareUps',
  'patchCauses',
  'patches',
  'patchesType',
  'patchLocations'
];
const fieldsRequired = ['skinRoughness', 'exfoliateSkin', 'chemicalPeeling'];

const FIELDS = [...fieldsRequired.map(requiredField), ...fieldsToReset.map(field)];

export default {
  name: 'Roughness',
  components: {
    RoughnessTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    skinRoughness(newValue, oldValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      if (!oldValue && this.exfoliateSkin && this.chemicalPeeling) {
        this.showNextStep();
      } else {
        this.scrollTo('#exfoliate-skin');
      }
    },
    exfoliateSkin(newValue, oldValue) {
      if (!oldValue && this.skinRoughness && this.chemicalPeeling) {
        this.showNextStep();
      } else {
        this.scrollTo('#chemical-peeling');
      }
    },
    chemicalPeeling(newValue, oldValue) {
      if (!oldValue && this.skinRoughness && this.exfoliateSkin) {
        this.showNextStep();
      }
    }
  }
};
</script>
